<template>
  <section>
    <form class="k-form" id="signupForm" @submit.prevent="submit()">
    <div class="pop-container">
      <div class="pop-conts">
        <span class="necessary">비밀번호를 변경해 주세요.</span>      
        <div data-role="grid" class="k-grid k-widget mgt30" style="border:none">
            <div class="" style="border:none">
              <h2>비밀 번호</h2>
              <div class="box_moc mgt10">
                <table class="moc_table " cellpadding="0">
                <colgroup>
                  <col width="15%" />
                  <col width="35%" />
                  <col width="15%" />
                  <col width="35%" />
                </colgroup>
                <tr>
                  <td class="txt_c bg_g necessary">
                    비밀번호
                  </td>
                  <td>
                    <kendo-maskedtextbox
                        type="password"
                        ref="userPwdRef"
                        style="width: 98%;"
                        minlength="8"
                        maxlength="50"
                        v-model.trim="userPwd"
                        placeholder="※8~20글자 영문,숫자,특수문자조합"
                    >
                    </kendo-maskedtextbox>
                  </td>
                  <td class="txt_c bg_g necessary">
                    비밀번호 재입력
                  </td>
                  <td>
                    <kendo-maskedtextbox
                        type="password"
                        ref="userPwdConfirmRef"
                        style="width: 98%;"
                        minlength="8"
                        maxlength="50"
                        v-model.trim="userPwdConfirm"
                        placeholder="비밀번호와 동일하게 입력해주세요."
                    >
                    </kendo-maskedtextbox>
                  </td>
                </tr>
                </table>
              </div>
            </div>
        </div>

        <div data-role="grid" class="k-grid k-widget mgt30" style="border:none">
            <div class="" style="border:none">
              <h2>기본정보를 확인해 주세요.</h2>
              <div class="box_moc mgt10">
                <table class="moc_table" cellpadding="0">
                <colgroup>
                  <col width="15%" />
                  <col width="35%" />
                  <col width="15%" />
                  <col width="35%" />
                </colgroup>
                <tr>
                  <td class="txt_c bg_g">
                    업체명
                  </td>
                  <td>
                      {{ userData.cmpyNm }}
                  </td>
                  <td class="txt_c bg_g">
                    아이디
                  </td>
                  <td>
                      {{ userData.userId }}
                  </td>
                </tr>
                <tr>
                  <td class="txt_c bg_g necessary">
                    성명
                  </td>
                  <td>
                      <input  type="text"
                              ref="userNmRef"
                              class="k-textbox"
                              autocomplete="off"
                              style="width: 98%;"
                              v-model.trim="userNm"
                              placeholder="성명" />
                  </td>
                  <td class="txt_c bg_g">
                    직급
                  </td>
                  <td>
                    <kendo-dropdownlist
                        ref="psnScnRef"
                        v-model.trim="psnScn"
                        :data-source="$store.state.codeRequestStore.search.combobox.psnScn.data"
                        :data-text-field="'cdNm'"
                        :data-value-field="'cdId'"
                        :option-label="'선택'"
                        style="width: 98%;"
                    >
                    </kendo-dropdownlist>
                  </td>
                </tr>
                <tr>
                  <td class="txt_c bg_g necessary">
                    휴대전화번호
                  </td>
                  <td>
                      <kendo-maskedtextbox  ref="spTelNoRef"
						                                v-model.trim="spTelNo"
						                                mask="999-0000-0000"
                                            style="width: 98%;"
                                            placeholder="000-0000-0000">
                      </kendo-maskedtextbox>
                  </td>
                  <td class="txt_c bg_g necessary">
                    이메일주소
                  </td>
                  <td>
                    <input  type="email"
                            ref="emailRef"
                            name="email"
                            class="k-textbox"
                            v-model.trim="email"
                            autofocus
                            style="width: 98%;"
                            placeholder="aaa@aaa.com" />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="join_agree_wrap" id="join">
      <div class="small_title_wrap">EFS 개인정보처리방침에 동의해 주세요.</div>
      <div class="agree_allcheck">
        <div class="agree_check">
          <label>
            <input  type="checkbox"
                    id="agree_all"
                    class="k-checkbox"
                    v-model.trim="agrAll"
                    @change="e => { efsAgrDt = psiAgrDt = mktAgrAll = mktEmailAgrDt = mktSmsAgrDt = e.target.checked }">
            <label class="k-checkbox-label" for="agree_all"></label>
          </label>
        </div>
        <div class="allcheck_tit">
          모두 동의합니다.
        </div>
        <div class="allcheck_txt">
            전체동의는 필수 및 선택정보에 대한 동의도 포함되어 있으며, 개별적으로도 동의를 선택하실 수 있습니다. <br />
            선택항목에 대한 동의를 거부하시는 경우에도 서비스는 이용이 가능합니다. 

        </div>
      </div>
      <div class="agree_list w100">
        <ul class="">
          <li class="agree_tit">
            <div class="agree_check">
              <label>
                <input  type="checkbox"
                        ref="efsAgrRef"
                        id="agree01"
                        name="agree01"
                        class="k-checkbox"
                        v-model.trim="efsAgrDt" />
                <label class="k-checkbox-label" for="agree01"></label>
              </label>
            </div>
            <div class="agree_tit_txt">
              <a @click="popup('service')" style="cursor: pointer;">[필수] EFS 이용약관 </a>
              <a @click="popup('service')" style="cursor: pointer;"><span class="k-icon k-i-arrow-chevron-right"></span></a>
            </div>
          </li>
          <li class="agree_tit">
            <div class="agree_check">
              <label>
                <input  type="checkbox"
                        ref="psiAgrRef"
                        id="agree02"
                        name="agree02"
                        class="k-checkbox"
                        v-model.trim="psiAgrDt" />
                <label class="k-checkbox-label" for="agree02"></label>
              </label>
            </div>
            <div class="agree_tit_txt">
              <a @click="popup('privacy')" style="cursor: pointer;">[필수] 개인정보 활용 동의</a>
              <a @click="popup('privacy')" style="cursor: pointer;"><span class="k-icon k-i-arrow-chevron-right"></span></a>
            </div>
          </li>
          <li class="agree_tit">
            <div class="agree_check">
              <label>
                <input type="checkbox" id="agree03" class="k-checkbox" v-model.trim="mktAgrAll" @change="e => { mktEmailAgrDt = mktSmsAgrDt = e.target.checked }">
                <label class="k-checkbox-label" for="agree03"></label>
              </label>
            </div>
            <div class="agree_tit_txt">
              <a @click="popup('marketing')" style="cursor: pointer;">[선택] 마케팅 활용 동의</a>
              <a @click="popup('marketing')" style="cursor: pointer;"><span class="k-icon k-i-arrow-chevron-right"></span></a>
            </div>
            <div class="agree_option">
              <div class="agree_check">
                <label>
                  <input type="checkbox" id="agree04" class="k-checkbox" v-model.trim="mktEmailAgrDt">
                  <label class="k-checkbox-label" for="agree04"></label>
                </label>
              </div>
              <div class="agree_tit_txt">
                <a href="#">E-mail</a>
              </div>
            </div>
            <div class="agree_option">
              <div class="agree_check">
                <label>
                  <input type="checkbox" id="agree05" class="k-checkbox" v-model.trim="mktSmsAgrDt">
                  <label class="k-checkbox-label" for="agree05"></label>
                </label>
              </div>
              <div class="agree_tit_txt">
                <a href="#">SMS</a>
              </div>
            </div>

          </li>
        </ul>
      </div>
    </div>
    <div class="button_area w40">
      <ul>
        <li>
          <button type="submit" class="large_btn orange">저장</button>
        </li>
      </ul>
    </div>
    </form>
  </section>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'

export default {
  name: 'FirstLogin',
  props: {
    userData: {
      type: Object,
      required: true,
    }
  },
  watch: {
    userData: function(newVal) {

      this.userNm = newVal.userNm
      this.psnScn = newVal.psnScn
      this.spTelNo = newVal.spTelNo
      this.email = newVal.email
    },
    efsAgrDt: function() {
      this.setAgrAll()
    },
    psiAgrDt: function() {
      this.setAgrAll()
    },
    mktEmailAgrDt: function() {
      this.setAgrAll()
    },
    mktSmsAgrDt: function() {
      this.setAgrAll()
    },
  },
  methods: {
    popup: function (fileNm) {
      if (fileNm === 'privacy') {
        window.open('//etners.com/terms/management/detail/10/02')
      }else if(fileNm === 'service'){
        window.open('//etners.com/terms/management/detail/10/01')
      }else{
        window.open('//etners.com/'+fileNm+'.html?solCd=10')
      }
    },
    setAgrAll() {

      if(this.mktEmailAgrDt && this.mktSmsAgrDt) this.mktAgrAll = true
      else if(!this.mktEmailAgrDt || !this.mktSmsAgrDt) this.mktAgrAll = false

      if(this.efsAgrDt && this.psiAgrDt && this.mktEmailAgrDt && this.mktSmsAgrDt) this.agrAll = true
      else if(!this.efsAgrDt || !this.psiAgrDt || !this.mktEmailAgrDt || !this.mktSmsAgrDt) this.agrAll = false
    },
    validator() {
      const vm = this

      if(!vm.userPwd) {
        kendo.alert('새비밀번호를 입력해 주세요.').bind('close', function() {
          focusing(vm.$refs.userPwdRef.kendoWidget().element)
        })
        return false
      }
      if(!vm.userPwdConfirm) {
        kendo.alert('새비밀번호확인을 입력해 주세요.').bind('close', function() {
          focusing(vm.$refs.userPwdConfirmRef.kendoWidget().element)
        })
        return false
      }
      const matched = /(?=.*\d{1,50})(?=.*[~`!@#$%\^&*()-+=]{1,50})(?=.*[a-zA-Z]{1,50}).{8,50}$/.test(this.userPwd.trim())
      if (!matched) {
        kendo.alert('비밀번호는 8자 이상의 문자,숫자,특문으로 작성해주세요.').bind('close', function() {
          focusing(vm.$refs.userPwdRef.kendoWidget().element)
        })
        return false
      }
      if (this.userPwd.trim() !== this.userPwdConfirm.trim()) {
        kendo.alert('새 비밀번호와 맞지 않습니다.<br>기재한 비밀번호를 확인해주세요.').bind('close', function() {
          focusing(vm.$refs.userPwdConfirmRef.kendoWidget().element)
        })
        return false
      }
      if(!vm.userNm.trim()) {
        kendo.alert('성명을 입력해 주세요.').bind('close', function() {
          focusing(vm.$refs.userNmRef)
        })
        return false
      }
      if(!vm.spTelNo.trim()) {
        kendo.alert('휴대전화번호를 입력해 주세요.').bind('close', function() {
          focusing(vm.$refs.spTelNoRef.kendoWidget().element)
        })
        return false
      }
      if(!vm.email.trim()) {
        kendo.alert('이메일주소를 입력해 주세요.').bind('close', function() {
          focusing(vm.$refs.emailRef)
        })
        return false
      }
      if(!EtnersCommonUtil.validCheckEmail(this.email.trim())) {
        kendo.alert('이메일주소 형식에 맞게 입력해주세요.').bind('close', function() {
          focusing(vm.$refs.emailRef)
        })
        return false
      }
      if(!vm.efsAgrDt) {
        kendo.alert('EFS 이용약관에 동의해 주세요.').bind('close', function() {
          focusing(vm.$refs.efsAgrRef)
        })
        return false
      }
      if(!vm.psiAgrDt) {
        kendo.alert('개인정보 활용 동의해 주세요.').bind('close', function() {
          focusing(vm.$refs.psiAgrRef)
        })
        return false
      }

      function focusing(target) {
        if(target) {
          setTimeout(function() {
            target.focus()
          }, 100)
        }
      }

      return true

    },
    submit(e) {
      const vm = this

      if (vm.validator()) {

        let params = {
          loginUserToken: vm.$store.state.loginStore.lutk,
          spTelNo: vm.$refs.spTelNoRef.kendoWidget().raw(),
          userPwd: vm.userPwd,
          psnScn: vm.psnScn,
          email: vm.email,
          userNm : vm.userNm,
          efsAgrDt: vm.efsAgrDt,
          psiAgrDt: vm.psiAgrDt,
          mktEmailAgrDt: vm.mktEmailAgrDt,
          mktSmsAgrDt: vm.mktSmsAgrDt,
        }
        ApiUtil.post(`${ApiConfig.efsDomain}/efs/user/user-info`, params).then(function (response) {

          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }

          vm.$emit('go-dash-board')

        })

      }
    },
  },
  data() {
    return {
      userPwd: '',
      userPwdConfirm: '',
      userNm: '',
      psnScn: null,
      spTelNo: '',
      email: '',
      agrAll: false,
      efsAgrDt: false,
      psiAgrDt: false,
      mktEmailAgrDt: false,
      mktSmsAgrDt: false,
      mktAgrAll: false,
    }
  }
}
</script>

<style scoped></style>
