<template>
  <div>
    <h1 class="login_small_title_wrap">
      <!-- // TODO : FMS 로고 -->
        <img @click="goLanding"
             src="../../assets/img/etners-main/logo_efs.png" style="cursor: pointer">
    </h1>
    <form class="k-form" @submit.prevent="login(userId, userPwd)">
      <fieldset>
        <label class="k-form-field">
          <input  type="id"
                  class="k-textbox"
                  placeholder="아이디"
                  autofocus
                  v-model.trim="userId"
                  />
        </label>
        <label class="k-form-field">
          <input  type="password"
                  class="k-textbox"
                  placeholder="패스워드"
                  autocomplete="off"
                  v-model.trim="userPwd"
                  />
        </label>
        <label class="k-form-field mtb10">
          <input  type="checkbox"
                  id="auth-2fa"
                  class="k-checkbox"
                  v-model="checked"
                  checked="checked" />
          <label class="k-checkbox-label" for="auth-2fa"> 아이디 기억하기</label>
        </label>
        <button type="submit" class="mid_btn orange login_btn"> 로그인 </button>
      </fieldset>
      <div class="kfont">
        <router-link :to="{ name: 'FindAccount', params: { prop_active: 0 } }" tag="a">아이디 찾기</router-link> |
        <router-link :to="{ name: 'FindAccount', params: { prop_active: 2 } }" tag="a">비밀번호 변경</router-link>
        <template v-if="$store.state.loginStore.saasMode"> |
          <router-link :to="{ name: 'Join' }" tag="a">회원가입</router-link>
        </template>
      </div>
      <button class="mid_btn w100 btn_business" @click="goLanding" src="../../assets/img/etners-main/logo_efs.png" > EFS 사업소개
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mgl5" style="width:15px;">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"/>
          </svg>
      </button>

    </form>

    <!-- 최초로그인 kendo window -->
    <kendo-window ref="firstLoginWindowRef"
                  :title="'EFS 최초로그인'"
                  :modal="true"
                  :width="'1000px'"
                  style="z-index: 10004; transform: scale(1); opacity: 1; display:none; min-height: 700px"
    >
      <first-login :user-data="loginUserData" @go-dash-board="beforeGo()"></first-login>
    </kendo-window>
  </div>
</template>


<script>
import util from '@ebmp-fe-common-module/module/src/common/util'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import Constant from "@/common/constant";
import FirstLogin from '@/views/login/FirstLogin'
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  computed : {
    storeLoginUserData: function() {
      return this.$store.getters['loginUserData']
    },
    ...mapGetters(['loginUserToken'])
  },
  mounted () {
    let savedUserId = util.getUserSavedId()
    let existSavedUserIdFl = false
    if (savedUserId !== '' && savedUserId != null) {
      existSavedUserIdFl = true
    }
    this.checked = existSavedUserIdFl
  },
  methods: {
    // 로그인 validation
    validationLogin() {

      if(EtnersCommonUtil.isEmpty(this.userId.trim())) {
        kendo.alert('아이디를 입력해주세요.')
        return false
      }

      if (EtnersCommonUtil.isEmpty(this.userPwd.trim())) {
        kendo.alert('비밀번호를 입력해주세요.')
        return false
      }

      // TODO : 비밀번호 rule 보강
      if (this.userPwd.length < 8) {
        kendo.alert('비밀번호 항목의 값은 최소 8글자이어야 합니다.')
        return false
      }

      return true

    },
    // 로그인 처리
    login (userId, userPwd) {
      const vm = this

      //만약 여러번 로그인이 수행되었을 경우를 대비해서 기존 데이터를 삭제한다.
      // sessionStorage.removeItem('userInfo')
      // sessionStorage.removeItem('isFirstLogin')


      if (vm.validationLogin()) {

        // id 기억하기가 체크 시 localstorage 저장
        if (this.checked) {
          util.saveUserId(userId)
        } else {
          util.destroyUserId(userId)
        }

        let params = {
          userId,
          userPwd,
          wmGbn: Constant.fms.wmGbn.WEB_CD,
        }
        // 로그인 처리
        this.$store.dispatch('FETCH_LOGIN', params).then(loginData => {

          if (loginData === undefined || loginData.resultStatus === undefined) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (loginData.resultStatus.messageCode !== '2000') {
            kendo.alert(loginData.resultStatus.messageText)
            return false
          }

          params = {
            loginUserToken: loginData.resultData.loginUserToken,
            wmGbn: Constant.fms.wmGbn.WEB_CD,
          }
          // 로그인 사용자 정보 조회
          this.$store.dispatch('FETCH_LOGIN_USER_DATA', params).then(loginUserData => {

            if (loginUserData === undefined || loginUserData.resultStatus === undefined) {
              kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
              return false
            }
            if (loginUserData.resultStatus.messageCode !== '2000') {
              kendo.alert(loginUserData.resultStatus.messageText)
              return false
            }
            // 로그인 성공시 공통코드맵 호출
            vm.$store.dispatch('initSearchDataForCodeRequest')

            if(loginUserData.resultData.authCd === Constant.efs.auth.TEMPORARY_USER){
              window.location.href='/landing'
            }else {
              // 메뉴 권한 조회
              this.$store.dispatch('FETCH_MENU_DATA').then(menuResponse=>{
                if (menuResponse.resultStatus.messageCode !== '2000') {
                  kendo.alert(menuResponse.resultStatus.messageText)
                  return false
                }

                if(!loginData.resultData.firstLoginYn || loginData.resultData.firstLoginYn == 'Y'){
                  // 최초 로그인인 경우 최초 로그인 팝업
                  this.loginUserData = loginUserData.resultData
                  vm.$refs.firstLoginWindowRef.kendoWidget().center().open()
                } else if(loginData.resultData.pwdChgYn === 'Y'){
                  // 기간에 따른 비밀번호 업데이트를 해야하는 경우
                  vm.goChangePassword()
                } else {
                  // 최초 로그인이 아닌 경우 바로이동
                  vm.beforeGo()
                }
              })
            }

          })
        })
      }
    },
    beforeGo() {
      const vm = this

      // sassMode인경우 무조건 렌딩페이지로 이동
      if(vm.$store.state.loginStore.saasMode){
        vm.goLanding()
      }
      // 접근가능한 회사가 없는 경우
      else if(vm.storeLoginUserData?.firstAceessInfoCmpyMng.length < 1){
        kendo.alert("접근가능한 회사가 없습니다.").bind('close', function() {
          vm.goLanding()
        })
      }
      else if(vm.storeLoginUserData?.firstAceessInfoCmpyMng.length === 1
          && [Constant.efs.auth.LOGISTICS_MANAGER, Constant.efs.auth.CLIENT_USER].includes(vm.storeLoginUserData.authCd))
      {
        let accessCmpyInfoSysGbn = vm.storeLoginUserData?.firstAceessInfoCmpyMng[0].sysGbn
        let mode = location.hostname.includes('dev') || location.hostname.includes('localhost') ? 'dev-' : ''
        let linkCmpyCd
        let domain
        switch (accessCmpyInfoSysGbn) {
          case vm.customCmpyConstant.efs :
            vm.goDashBoard()
            break
          case vm.customCmpyConstant.fms :
            domain = `${mode}fms.etnersefs.com`
            linkCmpyCd = vm.storeLoginUserData?.firstAceessInfoCmpyMng[0].cmpyCd
            break
          case vm.customCmpyConstant.mega :
            domain = `${mode}mega.etnersefs.com/lgn/getSSOLogin.data`
            break;
          case vm.customCmpyConstant.cd :
            domain = `${mode}cd.etnersefs.com/lgn/getSSOLogin.data`
            break;
          case vm.customCmpyConstant.visang :
            domain = `${mode}visang.etnersefs.com/lgn/getSSOLogin.data`
            break;
        }
        if(vm.customCmpyConstant.efs !== accessCmpyInfoSysGbn ){
          window.location.href = `https://${domain}?loginUserToken=${vm.loginUserToken}${!!linkCmpyCd? '&linkCmpyCd='+linkCmpyCd : ''}`
        }
      }
      else {
        vm.goSelectCompany()
      }
    },
    // 기간에 따른 비밀번호 업데이트 화면으로 이동
    goChangePassword() {
      this.$router.push({
        name: 'ChangePassword',
      })
    },
    // 대시보드로 이동
    goDashBoard() {
      this.$router.push({
        name: 'DashBoard',
      })
    },
    goSelectCompany() {
      this.$router.push({
        path: '/enter',
      })
    },
    goLanding() {
      window.location.href = '/landing'
    }
  },
  components: {
    FirstLogin
  },
  data () {
    return {
      customCmpyConstant : {
        efs : '99999',
        fms : '01',
        mega : '02',
        cd : '03',
        visang : '04'
      },
      userId: window.localStorage.getItem('savedUserId'),
      userPwd: null,
      checked: null,
      loginUserData: {},
    }
  },
}
</script>

<style scoped="">
</style>
